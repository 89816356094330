import { classToClass, classToPlain, Exclude, plainToClassFromExist } from "class-transformer";
import { dateToInt, dateToYYYYMM01, intToDate, YYYYMMToDate } from "../library/utils";

export class ClientFormDataModel {
    public companyName: string;
    public display: string;
    public description: string;
    public displayRole: string;
    public status: string;
    public parameters: JSON;

    static typeProvider() {
        return ClientFormDataModel;
    }
}

export class MicroProfitParametersModel {
    public dataLimita: number;
    public explicatieNotificare: string;
    public luna: number;
    public schimbare: string;
    public lunaMicro: number;
    public plafon: number;

    public get lastDayOfMonth(): Date {
		let date: Date = YYYYMMToDate(this.luna);
        let lastDay: Date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        return lastDay;
	}

    public get lunaMicroAsDate(): Date {
        return YYYYMMToDate(this.lunaMicro)
    }

    public get nextYear(): Date {
        let date: Date = YYYYMMToDate(this.luna);
        let nextYear: Date = new Date(date.getFullYear() + 1, 0, 1);
        return nextYear;
    }
}

export class MicroProfitDataModel {
    public are1FTE: boolean;
    public areConsultanta80: boolean;
    public are253Micro: boolean;
    public pastreazaFirmaMicro: boolean;
    public ramaneMicro: boolean;
    public doresteMicro: boolean;
    public devineMicro: boolean;
    public ramaneProfit: boolean;
    public devineProfit: boolean;
    public micro1p: boolean;
    public micro3p: boolean;
    public caSub500k: boolean;
    public defaultAre1FTE: boolean;
    public defaultAreConsultanta80: boolean;
    public defaultAre253Micro: boolean;

    @Exclude()
    public hasCaenCode: boolean;

    public clone(): MicroProfitDataModel {
        return classToClass(this, { ignoreDecorators: true });
    }

    static typeProvider() {
        return MicroProfitDataModel;
    }
}

export class MicroProfitFormModel {
    public data: MicroProfitDataModel;
    public formData: ClientFormDataModel;
    public formDataParameters: MicroProfitParametersModel;

    static typeProvider() {
        return MicroProfitFormModel;
    }
}

export enum FormStatus {
	Created = 'Created',
	Draft = 'Draft',
	Submitted = 'Submitted',
    Deleted = 'Deleted'
}

export enum SistemImpozitareActivitatiIndependente {
    SistemReal = 'SistemReal',
    CoteForfetareDeCheltuieli = 'CoteForfetareDeCheltuieli',
    NormaDeVenit = 'NormaDeVenit'
}

export enum SistemImpozitareChirii {
    SistemReal = 'SistemReal',
    CoteForfetare = 'CoteForfetare',
}

export class DuPersonModel {
    public nume: string;
    public prenume: string;
    public cnp: string;
    public adresa: string;
    public telefon: string;
    public email: string;
    public iban: string;
    public areHandicap: boolean;
    public nerezident: boolean;

    static typeProvider() {
        return DuPersonModel;
    }
}

export class DuChiriiModel {
    public sistemImpozitare: string;
    public adresa: string;
    public numarDocument: number;
    public dataDocument: number;
    @Exclude()
	private _dataDocumentEx: Date;
	@Exclude()
	public get dataDocumentEx(): Date {
		if (!this._dataDocumentEx && this.dataDocument) {
			this._dataDocumentEx = intToDate(this.dataDocument);
		}
		return this._dataDocumentEx;
	}
	public set dataDocumentEx(value: Date) {
		if (this._dataDocumentEx != value) {
			this._dataDocumentEx = value;
			this.dataDocument = dateToInt(value);
		}
	}
    public dataInceperiiActivitatii: number;
    @Exclude()
	private _dataInceperiiActivitatiiEx: Date;
	@Exclude()
	public get dataInceperiiActivitatiiEx(): Date {
		if (!this._dataInceperiiActivitatiiEx && this.dataInceperiiActivitatii) {
			this._dataInceperiiActivitatiiEx = intToDate(this.dataInceperiiActivitatii);
		}
		return this._dataInceperiiActivitatiiEx;
	}
	public set dataInceperiiActivitatiiEx(value: Date) {
		if (this._dataInceperiiActivitatiiEx != value) {
			this._dataInceperiiActivitatiiEx = value;
			this.dataInceperiiActivitatii = dateToInt(value);
		}
	}
    public dataIncetariiActivitatii: number;
    @Exclude()
	private _dataIncetariiActivitatiiEx: Date;
	@Exclude()
	public get dataIncetariiActivitatiiEx(): Date {
		if (!this._dataIncetariiActivitatiiEx && this.dataIncetariiActivitatii) {
			this._dataIncetariiActivitatiiEx = intToDate(this.dataIncetariiActivitatii);
		}
		return this._dataIncetariiActivitatiiEx;
	}
	public set dataIncetariiActivitatiiEx(value: Date) {
		if (this._dataIncetariiActivitatiiEx != value) {
			this._dataIncetariiActivitatiiEx = value;
			this.dataIncetariiActivitatii = dateToInt(value);
		}
	}
    public venitBrut: number;
    public cheltuieliDeductibile: number;

    static typeProvider() {
        return DuChiriiModel;
    }
}

export class DuActivitatiIndependenteModel {
    public sistemImpozitare: string;
    public formaOrganizare: string = "Individual";
    public codCaen: string;
    public denCaen: string;
    public sediuSauIdentificareBun: string;
    public numarDocument: number;
    public dataDocument: number;
    @Exclude()
	private _dataDocumentEx: Date;
	@Exclude()
	public get dataDocumentEx(): Date {
		if (!this._dataDocumentEx && this.dataDocument) {
			this._dataDocumentEx = intToDate(this.dataDocument);
		}
		return this._dataDocumentEx;
	}
	public set dataDocumentEx(value: Date) {
		if (this._dataDocumentEx != value) {
			this._dataDocumentEx = value;
			this.dataDocument = dateToInt(value);
		}
	}
    public dataInceperiiActivitatii: number;
    @Exclude()
	private _dataInceperiiActivitatiiEx: Date;
	@Exclude()
	public get dataInceperiiActivitatiiEx(): Date {
		if (!this._dataInceperiiActivitatiiEx && this.dataInceperiiActivitatii) {
			this._dataInceperiiActivitatiiEx = intToDate(this.dataInceperiiActivitatii);
		}
		return this._dataInceperiiActivitatiiEx;
	}
	public set dataInceperiiActivitatiiEx(value: Date) {
		if (this._dataInceperiiActivitatiiEx != value) {
			this._dataInceperiiActivitatiiEx = value;
			this.dataInceperiiActivitatii = dateToInt(value);
		}
	}
    public dataIncheieriiActivitatii: number;
    @Exclude()
	private _dataIncheieriiActivitatiiEx: Date;
	@Exclude()
	public get dataIncheieriiActivitatiiEx(): Date {
		if (!this._dataIncheieriiActivitatiiEx && this.dataIncheieriiActivitatii) {
			this._dataIncheieriiActivitatiiEx = intToDate(this.dataIncheieriiActivitatii);
		}
		return this._dataIncheieriiActivitatiiEx;
	}
	public set dataIncheieriiActivitatiiEx(value: Date) {
		if (this._dataIncheieriiActivitatiiEx != value) {
			this._dataIncheieriiActivitatiiEx = value;
			this.dataIncheieriiActivitatii = dateToInt(value);
		}
	}
    public venitBrut: number;
    public cheltuieliDeductibile: number;
    public normaDeVenit: number;

    static typeProvider() {
        return DuActivitatiIndependenteModel;
    }
}

export class DuPfaParametersModel {
    public anEstimat: number;
    public anRealizat: number;
    public explicatieNotificare: string;
    public luna: number;
    public salSanatate: number;
    public limitaSanatate: number;
    public sanatate: number;
    public salPensie: number;
    public limitaPensie: number;
    public pensie: number;
}

export class CaenCodeModel {
    public code: string;
    public description: string;
    public industry: string;

    public get fullDisplay(): string {
        return `${this.code} - ${this.description}`;
    }

    static typeProvider() {
        return CaenCodeModel;
    }
}

export class DuPfaDataModel {
    public persoana: DuPersonModel;
    public anRealizat: number;
    public anEstimat: number;
    public rectificativaRealizat: boolean;
    public rectificativaEstimat: boolean;
    public chiriiRealizat: DuChiriiModel[];
    public activitatiIndependenteRealizat: DuActivitatiIndependenteModel[]
    public dreptAutorRealizat: number;
    public agriRealizat: number;
    public titluriValoareRealizat: number;
    public dividendeRealizat: number;
    public alteSurseRealizat: number;
    public chiriiEstimat: DuChiriiModel[];
    public activitatiIndependenteEstimat: DuActivitatiIndependenteModel[]
    public dreptAutorEstimat: number;
    public agriEstimat: number;
    public titluriValoareEstimat: number;
    public dividendeEstimat: number;
    public alteSurseEstimat: number;
    public optionalSanatateRealizat: boolean;
    public optionalSanatateEstimat: boolean;
    public optionalPensieRealizat: boolean;
    public optionalPensieEstimat: boolean;
    public optiunePensieValoareEstimat: number;
    public venituriStrainatate: number = 0;
    public cimNedeterminat: string;
    public impozitONG: boolean;
    public ongCIF: string;
    public ongIBAN: string;
    public comments: string;

    static typeProvider() {
        return DuPfaDataModel;
    }
}

export class DeclaratiaUnicaPfaModel {
    public data: DuPfaDataModel;
    public formData: ClientFormDataModel;
    public formDataParameters: DuPfaParametersModel;

    static typeProvider() {
        return DeclaratiaUnicaPfaModel;
    }
}

export class DuPfa2024ParametersModel {
    public anRealizat: number;
}

export class DuAnswers2024EstimatCASSModel {
    public bifaCASS: boolean;
    public optiuneCASS: string;
}

export class DuPfa2024DataModel {
    public persoana: DuPersonModel;
    public anRealizat: number;
    public rectificativaRealizat: boolean;
    public chiriiRealizat: DuChiriiModel[];
    public activitatiIndependenteRealizat: DuActivitatiIndependenteModel[]
    public dreptAutorRealizat: number;
    public agriRealizat: number;
    public titluriValoareRealizat: number;
    public dividendeRealizat: number;
    public alteSurseRealizat: number;
    public venituriStrainatate: number = 0;
    public cimNedeterminat: string;
    public comments: string;
    public estimatCASS: DuAnswers2024EstimatCASSModel;

    static typeProvider() {
        return DuPfa2024DataModel;
    }
}

export class DuPfa2024Model {
    public data: DuPfa2024DataModel;
    public formData: ClientFormDataModel;
    public formDataParameters: DuPfa2024ParametersModel;

    static typeProvider() {
        return DuPfa2024Model;
    }
}

export class FormTypeModel {
    public externalId: string;
    public display: string;
    public description: string;
    public displayForRole: string;
    public notificationChatTypeEid: string;

    static typeProvider() {
        return FormTypeModel;
    }
}

export class FormModel {
    public externalId: string;
    public display: string;
    public description: string;
    public formType: FormTypeModel;
    public instanceParameters: JSON;

    static typeProvider() {
        return FormModel;
    }
}

export class ClientFormModel {
    public clientEid: string;
    public clientName: string;
    public fiscalNumber: string;
    public form: FormModel;
    public formStatus: string;
    public answeredByUserEid: string;
    public answeredByUserName: string;
    public answeredDateTime: string;
    public createdByUserEid: string;
    public createdByUserName: string;
    public createdDateTime: string;

    static typeProvider() {
        return ClientFormModel;
    }
}

export class ClientFormListModel {
    public recordsCount: number;
    public first: number;
    public last: number;
    public pageSize: number;
    public data: ClientFormModel[];

    static typeProvider() {
        return ClientFormListModel;
    }
}
