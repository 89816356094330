<kendo-dialog *ngIf="showMarkChatAnsweredDialog" title="Adaugă comentariu" (close)="closeMarkChatAnsweredDialog(false)" [minWidth]="250" [width]="450">
    <mark-answered-dialog [model]="chatOpInput" (close)="closeMarkChatAnsweredDialog(false)" (save)="closeMarkChatAnsweredDialog(true, $event)">
    </mark-answered-dialog>
</kendo-dialog>

<kendo-dialog *ngIf="showDocumentDialog" class="documentDialog" [width]="1600" [height]="1200">
	<document-display [documentIndentification]="documentIndentification" (close)="closeDocumentDialog()"></document-display>
</kendo-dialog>

<kendo-dialog *ngIf="showMicroProfitForm" [width]="1000">
	<micro-profit-form [isDialog]="true" [clientEidParam]="clientEid" [instanceParam]="instance" (close)="closeMicroProfitFormDialog()"></micro-profit-form>
</kendo-dialog>

<kendo-dialog *ngIf="showDuPFAForm" [width]="1200">
	<du-pfa-keez-form [isDialog]="true" [clientEidParam]="clientEid" [instanceParam]="instance" (close)="closeDuPFAFormDialog()"></du-pfa-keez-form>
</kendo-dialog>

<kendo-dialog *ngIf="showMp2024Form" [width]="1000">
	<mp2024-form [isDialog]="true" [clientEidParam]="clientEid" [instanceParam]="instance" (close)="closeMp2024FormDialog()"></mp2024-form>
</kendo-dialog>

<kendo-dialog *ngIf="showMeetingInfo" [minWidth]="700" [width]="700" (close)="closeMeetingDialogInfo(false)">
    <div class="col-24 d-flex flex-wrap px-0 pb-2 align-items-center">
        <div class="col-24 d-flex flex-wrap align-items-center text-justify pt-2">
            <p>Bine ați venit în platforma de comunicare video între Clientul Keez și Contabilul Dedicat.</p>
            <p>Keez recomandă ca metodă de comunicare mesajul scris folosind cele 4 camere de chat specializate pe domeniile de cunoștințe: Connect Contabilitate, Connect Salarizare, Connect Facturare și Admin.</p>
            <p>Totuși, în cazuri <strong>excepționale</strong>, este mai potrivită comunicarea audio-video pentru rezolvarea unor probleme care sunt urgente sau deosebite de actvitatea normală. Aceste întâlniri Live nu pot constitui suport decizional pentru niciuna dintre părți. Keez și Contabilul Dedicat își asumă responabilitatea doar pentru mesajele scrise din Connect.</p>
            <p>Pentru a evita supraîncarcarea și blocarea Contabililor Keez, aceste întâlniri sunt limitate la 15 minute și se programează în prealabil în Connect.</p>
            <p>Cu scopul îmbunătățirii calității comunicării dintre Client și Contabil, Keez își rezervă dreptul de a înregistra prin sondaj unele convorbiri. Prin inițializarea convorbirii, toți participanții își exprimă acordul pentru această eventuală înregistrare.</p>
        </div>
        <div class="col-24 d-flex flex-wrap align-items-center justify-content-center pt-2">
            <button class="col-auto btn btn-primary waves-effect waves-light" (click)="closeMeetingDialogInfo(true)">
                <span>Am înțeles</span>
            </button>
        </div>
    </div>
</kendo-dialog>

<kendo-dialog *ngIf="showMeetingCalendar" [minWidth]="1000" [width]="1000">
	<time-slots-dialog [meetingRequest]="meetingRequest" [fromProposal]="fromProposal" [expertInfoPersonModel]="expertInfoPersonModel" [meetingInfoLastHistory]="meetingInfoLastHistory"
        (close)="closeMeetingCalendarDialog(false)" (save)="closeMeetingCalendarDialog(true, $event)">
    </time-slots-dialog>
</kendo-dialog>

<kendo-dialog *ngIf="showMeetingRequest" [minWidth]="700" [width]="700">
	<meeting-request-dialog [meetingRequestData]="meetingRequestData" [expertInfoPersonModel]="expertInfoPersonModel"
        (accept)="acceptMeetingRequest($event)"
        (change)="changeMeetingRequestInterval($event)"
        (cancel)="cancelMeetingRequest($event)"
        (close)="closeMeetingRequestDataDialog()">
    </meeting-request-dialog>
</kendo-dialog>

<div id="chatContainer" class="chat">
    <div class="room-list-container">
        <div class="room-list-header">
            <div *ngIf="!isClientAdmin" class="col btn-group btn-auto d-flex justify-content-center align-items-center px-4 py-2 pb-3" role="group">
                <button type="button" class="btn btn-standard filterButton text-lowercase {{filterStatus ? 'btn-light' : 'btn-secondary'}}" (click)="filterRoomsByStatus(null)">Toate</button>
                <button type="button" class="btn btn-standard filterButton text-lowercase {{filterStatus !== ChatStatus.Unanswered? 'btn-light' : 'btn-secondary'}}" (click)="filterRoomsByStatus(ChatStatus.Unanswered)">Nerezolvate</button>
            </div>
            <div *ngIf="!isClientAdmin" class="room-search-container col d-flex flex-wrap justify-content-between">
                <div class="room-search col">
                    <i class="mdi-action-search room-search-icon"></i>
                    <input class="room-search-input" [(ngModel)]="chatRoomSearch" />
                    <i *ngIf="!!chatRoomSearch" class="mdi-content-clear room-clear-icon" (click)="chatRoomSearch=''"></i>
                </div>
                <div class="d-flex align-self-center">
                    <i class="mdi-navigation-refresh pink-text text-accent-2 cursor-pointer font-size-1-5 pl-1" title="Împrospătează lista" (click)="refresh()"></i>
                </div>
            </div>
        </div>
        <cdk-virtual-scroll-viewport id="chatRoomsList" class="flex-fill room-list" itemSize="60" minBufferPx="900" maxBufferPx="900">
            <ng-container *cdkVirtualFor="let chat of filteredChats; let index = index;">
                <div class="room {{isCurrent(chat) ? 'selected' : ''}} {{isClientAdmin ? 'client-admin' : ''}}" style="height: 60px!important;"
                    (click)="joinRoom(chat)">
                    <div *ngIf="isClientAdmin" class="room-name">{{chat.chatTypeName}}</div>
                    <div *ngIf="!isClientAdmin" class="room-name">{{index+1}} - {{chat.clientName}}</div>
                    <div *ngIf="!isClientAdmin" class="room-type">{{chat.chatTypeName}}</div>

                    <!-- <span>s: {{ chat.chatStatus }}</span> -->
                    <!-- <span>un: {{ now - (chat.firstUnansweredMessage == null ? now : chat.firstUnansweredMessage.getTime()) }}</span> -->
                    <!-- <span>n: {{ now }}</span>
                    <span>fun: {{ (chat.firstUnansweredMessage == null ? now : chat.firstUnansweredMessage.getTime()) }}</span> -->

                    <div *ngIf="isClientAdmin">
                        <div *ngIf="chat.unseenMessages > 0" class="unread-count">{{chat.unseenMessages}}</div>
                    </div>
                    <div *ngIf="!isClientAdmin">
                        <div *ngIf="chat.unansweredMessages > 0" class="unanswered-count {{ notificationDotColor(chat) }}"
                            title="{{onMessagesCountMouseOver(chat.chatStatus)}}">
                            {{chat.unansweredMessages}}
                        </div>
                        <div *ngIf="chat.unansweredMessages == 0 && chat.unseenMessages > 0" class="unanswered-count {{ notificationDotColor(chat) }}"
                            title="{{onMessagesCountMouseOver(chat.chatStatus)}}">
                            {{chat.unseenMessages}}
                        </div>
                        <div *ngIf="chat.unansweredMessages == 0 && chat.unseenMessages == 0 && chat.chatStatus > 0" class="unanswered-count {{ notificationDotColor(chat) }}"
                            title="{{onMessagesCountMouseOver(chat.chatStatus)}}">
                            -
                        </div>
                    </div>
                    <button *ngIf="isClientAdmin" class="ml-2 px-2 col-auto border-0 waves-effect waves-light pink-text action-button action-button-position" 
                        title="{{chat.chatStatus == 2 ? 'Trimite kind reminder' : chat.chatStatus == 3 ? 'Important' : ''}}"
                        (click)="chat.chatStatus == 2 ? markClientChatUrgent(chat) : null">
                            <i *ngIf="chat.chatStatus == 2" class="mdi-social-notifications-on"></i>
                            <i *ngIf="chat.chatStatus == 3" class="mdi-alert-error"></i>
                    </button>
                    <button *ngIf="!isClientAdmin" class="ml-2 px-2 col-auto border-0 waves-effect waves-light pink-text action-button" 
                        title="{{chat.chatStatus == 0 ? 'Păstrează nerezolvat' : 'Marchează rezolvat'}}"
                        (click)="chat.chatStatus == 0 ? keepClientChatImportant(chat) : markChatAnswered(chat)">
                            <i *ngIf="chat.chatStatus == 0" class="mdi-navigation-check"></i>
                            <i *ngIf="chat.chatStatus != 0" class="mdi-toggle-check-box-outline-blank"></i>
                    </button>
                </div>
            </ng-container>
        </cdk-virtual-scroll-viewport>
        <div *ngIf="false" class="room-list-footer">
            <!--button class="btn btn-primary waves-effect waves-light" type="submit" name="action" i18n="@@Actions$Send" (click)="this.sendBulk()">
                <i class="mdi-content-save"></i>
                Trimite bulk
            </button-->
        </div>
    </div>
    <div class="room-messages-outer">
        <div *ngIf="isInAnyRoom" class="room-messages-inner">
            <div class="col-auto d-flex px-0 mr-2 align-items-center justify-content-between">
                <div style="float:left">
                    <ng-container *ngIf="authorIsKeez || authorIsExpert">
                        <button *ngIf="!isClientAdmin" class="col-auto ml-2 pl-0 pr-2 mb-1 border-0 waves-effect waves-light pink-text action-button" 
                            title="{{chatService.currentChatRoom.chatStatus == 0 ? 'Păstrează nerezolvat' : 'Marchează rezolvat'}}"
                            (click)="chatService.currentChatRoom.chatStatus == 0 ? keepClientChatImportant(chatService.currentChatRoom) : markChatAnswered(chatService.currentChatRoom)">
                                <i *ngIf="chatService.currentChatRoom.chatStatus == 0" class="mdi-navigation-check"></i>
                                <i *ngIf="chatService.currentChatRoom.chatStatus != 0" class="mdi-toggle-check-box-outline-blank"></i>
                        </button>
                        <h5 style="display:inline">{{chatService.currentChatRoom.clientName}}</h5>
                        &nbsp;
                        (<h6 style="display:inline">{{chatService.currentChatRoom.chatTypeName}}</h6>)
                    </ng-container>
                </div>
                <div *ngIf="showExpertStartMeeting || (showClientStartMeeting && chatService.isFINChatRoom)" style="float:right" class="mb-2"> 
                    <!-- showStartMeeting && isClientAdmin && chatService.isFINChatRoom -->
                    <button class="border-0 waves-effect waves-light action-button {{ startMeetingDisabled ? 'grey-text' : 'pink-text'}}"
                        [title]="startMeetingDisabled ? 'Funcționalitate temporar inactivă' : (showExpertStartMeeting ? 'Începe o întâlnire imediat' : 'Programează o întâlnire')" 
                        (click)="requestVideo()" [disabled]="startMeetingDisabled">
                        <i class="mdi-av-videocam font-size-1-5"></i>
                    </button>
                </div>
            </div>
            <div #messagesContainer id="messagesContainer" class="messages-container">
                <div id="messagesContainerInner" class="messages-container-inner" style="position:relative;">
                    <div *ngIf="!!nextChunkEid" #nextMessagesLoader class='loader-container'>
                        <div class="loader"></div>
                    </div>
                    <div *ngFor="let message of messages; let messageIndex = index;" class="message-bubble-container">
                        <div *ngIf="messageIndex == messages.length-1 || message.datePostedInt != messages[messageIndex+1].datePostedInt"
                            class="date-of-messages">
                            {{message.datePosted | kendoDate:'dd MMMM yyyy'}}</div>
                        <div id="message{{message.messageId}}"
                            class="pr-0 d-flex flex-nowrap message-bubble {{messageBubbleClass(message, messageIndex)}}">
                            <div class="col px-0 d-flex flex-column">
                                <div class="message-bubble-header">
                                    <span class="col px-0 m-0 font-weight-bold">{{message.authorName}} {{message.authorIsKeez ? '(Keez)' : message.authorIsExpert ? '(Expert Contabil)' : '' }}</span>
                                    <span class="col-auto text-lowercase px-0 m-0">{{message.datePosted | kendoDate:'HH:mm'}}</span>
                                </div>
                                <div #customMD class="message" [innerHTML]="markdown(message.content)"></div>
                            </div>
                            <div class="col-auto px-0 d-flex flex-column move-to">
                                <i class="col-auto px-1 mdi-content-reply mdi-flip-horizontal cursor-pointer"
                                    (click)="replyMessage(message)"></i>
                                <dropdown-button id="moveMessage{{message.messageId}}" class="" list-class="move-to-list"
                                    container="#messagesContainerInner">
                                    <i class="col-auto px-1 mdi-content-forward cursor-pointer"></i>
                                    <dropdown-button-item *ngFor="let chatType of filteredChatTypes" class="py-0" [link]="false"
                                        (itemClick)="moveMessage(message, chatType)">
                                        {{chatType.name}}
                                    </dropdown-button-item>
                                </dropdown-button>
                            </div>
                        </div>
                        <div #unreadMessages id="unreadMessages" *ngIf="!!displayMessageId && message.messageId == displayMessageId"
                            class="unread-messages">
                            Mesaje necitite</div>
                    </div>
                    <div *ngIf="!!prevChunkEid" #prevMessagesLoader class='loader-container'>
                        <div class="loader"></div>
                    </div>
                </div>
            </div>

            <i *ngIf="showScrollToBottom" class="mdi-av-fast-forward mdi-rotate-90 pink-text font-size-1-5 btn-scroll-bottom" (click)="scrollToBottom()"></i>
            <div *ngIf="!loadingInitialChunk" class="message-input-container">
                <div class="message-input-outer">
                    <div class="message-input-inner">
                        <textarea id="textInput" class="message-input" rows=1 maxlength="5000" [(ngModel)]="message" (cut)="delayedResizeTextInput()"
                            (paste)="delayedResizeTextInput()" (drop)="delayedResizeTextInput()" (keydown)="textInputKeyDown($event)"
                            (keypress)="textInputKeyPress($event)"></textarea>
                        <textarea kendoTextArea id="textHiddenInput" class="message-input-hidden" rows=1></textarea>
                    </div>
                </div>
                <button class="btn btn-primary btn-send-message waves-effect waves-light" type="submit" name="action" (click)="this.sendMessage()">
                    <i class="mdi-content-send"></i>
                </button>
            </div>
        </div>
    </div>
</div>